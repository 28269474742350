import React from 'react';

import { BurgerIcon } from '@dsch/icons';
import styled from 'styled-components';

const BurgerContainer = styled.button`
  display: flex;
  padding: 0;
  background: none;
  color: #333333;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Burger = ({
  className,
  onClick,
  ...props
}: {
  className?: string;
  onClick?: () => void;
}) => (
  <BurgerContainer className={className} onClick={onClick}>
    <BurgerIcon {...props} />
  </BurgerContainer>
);
