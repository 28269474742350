import React from 'react';

import { DaftGreyIcon } from '@dsch/icons';
import Link from 'next/link';
import styled from 'styled-components';
import DistilledFamily from '../../../tempIcons/DistilledFamily';

import { Footer, FooterProps } from '../../../components/Toolkit/Footer/Footer';
import { PAGE_BREAK_POINT_800PX } from '../../../types/breakpoints';

const PAGE_BREAK_POINT_550 = '550px';

const ContentContainer = styled.div<{ isMobileView?: boolean }>`
  @media only screen and (min-width: ${PAGE_BREAK_POINT_800PX}) {
    ${({ isMobileView, theme }) =>
      !isMobileView &&
      `
      ${theme.fontSize.B12}
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    `}
  }
`;

const LeftContent = styled.div<{ isMobileView?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.M20};

  @media only screen and (min-width: ${PAGE_BREAK_POINT_800PX}) {
    margin-bottom: 0;
    ${({ isMobileView, theme }) =>
      isMobileView &&
      `display: flex;
      align-items: center;
      margin-bottom: ${theme.spacing.M20};
      `}
  }
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.fontSize.B12}
`;

const LogoContainer = styled.div`
  display: inline-block;
  svg {
    width: 24px;
    height: 24px;
  }
  padding-right: 8px;
`;

const StringContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media only screen and (min-width: ${PAGE_BREAK_POINT_550}) {
    font-size: 12px;
  }
`;

const DistilledFamilyIconContainer = styled.div<{ isMobileView?: boolean }>`
  svg {
    width: 158px;
    height: 16px;
    color: ${({ theme }) => theme.color.GREY_DARKER};
  }
  margin-bottom: ${({ theme }) => theme.spacing.S4};

  @media only screen and (min-width: ${PAGE_BREAK_POINT_800PX}) {
    margin-bottom: ${({ theme }) => theme.spacing.S8};
    margin-left: auto;

    ${({ isMobileView, theme }) =>
      isMobileView &&
      `
      margin-left: 0;
      margin-bottom: ${theme.spacing.S4};
  `}
  }
`;

const BrandsList = styled.ul`
  display: flex;
`;

const Brand = styled.li`
  :not(:first-of-type) {
    :before {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      display: inline-block;
      background-color: ${({ theme }) => theme.color.GREY_LIGHT};
      vertical-align: middle;
      margin: 0 ${({ theme }) => theme.spacing.S8};
    }
  }

  color: ${({ theme }) => theme.color.GREY_DARKER};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
`;

const StyledA = styled.a`
  color: ${({ theme }) => theme.color.GREY_DARKER};
`;

export const FooterDefault = (props: FooterProps) => {
  const { navLinks, breadcrumbs, routes, isMobileView, hideFooterOnMobile } =
    props;

  return (
    <Footer
      BASE_URL={'/'}
      navLinks={navLinks}
      hideFooterOnMobile={hideFooterOnMobile}
      breadcrumbs={breadcrumbs}
      routes={routes}
      isMobileView={isMobileView}
      content={
        <ContentContainer isMobileView={isMobileView}>
          <LeftContent isMobileView={isMobileView}>
            <LogoContainer>
              <DaftGreyIcon />
            </LogoContainer>
            <StringContainer>
              <p>
                © Daft Media Limited 1997-
                {new Date().getFullYear()}
                .&nbsp;
              </p>
              <p>Registered in Ireland under Company Number 347856</p>
            </StringContainer>
          </LeftContent>
          <RightContent>
            <DistilledFamilyIconContainer isMobileView={isMobileView}>
              <DistilledFamily />
            </DistilledFamilyIconContainer>
            <BrandsList data-testid="footer_brand_links">
              {[
                {
                  text: 'DoneDeal.ie',
                  link: 'https://www.donedeal.ie',
                },
                {
                  text: 'Adverts.ie',
                  link: 'https://www.adverts.ie',
                },
                {
                  text: 'Jobs at distilled',
                  link: 'https://www.distilled.ie',
                },
              ].map(({ link, text }, index) => {
                return (
                  <Brand key={index}>
                    <Link href={link} passHref={true} legacyBehavior>
                      <StyledA target="_blank">{text}</StyledA>
                    </Link>
                  </Brand>
                );
              })}
            </BrandsList>
          </RightContent>
        </ContentContainer>
      }
    />
  );
};
