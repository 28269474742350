import React from 'react';

import styled from 'styled-components';

export type LabelProps = {
  labelText: string;
  labelType:
    | 'primary'
    | 'secondary'
    | 'alert'
    | 'success'
    | 'success_light'
    | 'disabled';
  labelSize: 'xsmall' | 'small' | 'large';
  testId?: string;
  iconComponent?: JSX.Element;
  hasFill?: boolean;
};

const getLabelType = (labelType: string, theme: any, hasFill: boolean) => {
  switch (labelType) {
    case 'primary':
      return `
        color: ${hasFill ? theme.color.WHITE : theme.color.GREY_DARKER};
        border: 1px solid ${theme.color.GREY_DARKER};
        background: ${hasFill ? theme.color.GREY_DARKER : ''};
        `;
    case 'secondary':
      return `
        color: ${hasFill ? theme.color.WHITE : theme.color.PRIMARY};
        border: 1px solid ${theme.color.PRIMARY};
        background: ${hasFill ? theme.color.PRIMARY : ''};
        `;
    case 'alert':
      return `
        color: ${hasFill ? theme.color.WHITE : theme.color.ERROR};
        border: 1px solid ${theme.color.ERROR};
        background: ${hasFill ? theme.color.ERROR : ''};
        `;
    case 'success':
      return `
        color: ${hasFill ? theme.color.WHITE : theme.color.SUCCESS};
        border: 1px solid ${theme.color.SUCCESS};
        background: ${hasFill ? theme.color.SUCCESS : ''};
        `;
    case 'success_light':
      return `
        color: ${hasFill ? theme.color.WHITE : theme.color.SUCCESS_LIGHT};
        border: 1px solid ${theme.color.SUCCESS_LIGHT};
        background: ${hasFill ? theme.color.SUCCESS_LIGHT : ''};
        `;
    case 'disabled':
      return `
        color: ${hasFill ? theme.color.WHITE : theme.color.GREY_DARK};
        border: 1px solid ${theme.color.GREY_DARK};
        background: ${hasFill ? theme.color.GREY_DARK : ''};
        `;
    default:
      return '';
  }
};

const StyledSpan = styled.span<{
  labelType: string;
  labelSize: string;
  hasFill: boolean;
}>`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  border-radius: ${({ theme }) => theme.spacing.S4};
  height: ${({ theme }) => theme.spacing.M20};
  padding: 0px ${({ theme }) => theme.spacing.S8};
  background: ${({ theme }) => theme.color.WHITE};

  ${({ theme }) => theme.fontSize.B10}
  ${({ labelType, theme, hasFill }) => getLabelType(labelType, theme, hasFill)};

  @media only screen and (min-width: 600px) {
    ${({ labelSize, theme }) => {
      switch (labelSize) {
        case 'xsmall':
          return `
          vertical-align: text-bottom;
          height: 16px;  
          padding: 0px ${theme.spacing.S4}; 
          ${theme.fontSize.B10}`;

        case 'small':
          return `
          vertical-align: text-bottom;
          height: ${theme.spacing.M20};  
          padding: 0px ${theme.spacing.S8}; 
          ${theme.fontSize.B10}`;

        case 'large':
          return `
          vertical-align: bottom;;
          height: 30px;  
          padding: 0px 10px;  
          ${theme.fontSize.P14}`;
      }
    }};
  }
`;

const IconContainer = styled.span`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing.S4};
  align-items: center;
`;

export const Label = (props: LabelProps) => {
  const {
    labelType,
    labelText,
    labelSize = 'large',
    testId,
    iconComponent,
    hasFill = false,
    ...rest
  } = props;
  const UppercaseLabelText = labelText.toUpperCase();
  return (
    <StyledSpan
      labelSize={labelSize}
      labelType={labelType}
      data-testid={`label-${testId || labelType}`}
      hasFill={hasFill}
      {...rest}
    >
      {iconComponent && <IconContainer>{iconComponent}</IconContainer>}
      {UppercaseLabelText}
    </StyledSpan>
  );
};
