import React, { forwardRef } from 'react';

import { NewTabIcon } from '@dsch/icons';

import { IconWithContentAndLabel } from '../../../../../components/Toolkit/IconWithContentAndLabel/IconWithContentAndLabel';
import { NavigationItemProps } from '../../../../../config/headerNavigation';

import CollapsingItem from './CollapsingItem';
import * as Styled from './Navigation.styled';
import { LabelContainerProps, NavigationProps } from './Navigation.types';

const TopLevelItemActiveFakeButtonWithRef = forwardRef((props: any, ref) => (
  <Styled.TopLevelItemActiveFakeButton {...props} {...ref} />
));

const TopLevelItemActiveLinkWithRef = forwardRef((props: any, ref) => (
  <Styled.TopLevelItemActiveLink {...props} {...ref} />
));

const NavTitleWrapper = ({
  title,
  hasNewLabel,
  hasNewSubtitleLabel,
  labelSize = 'xsmall',
  labelText = 'New',
  labelType = 'success',
}: LabelContainerProps): JSX.Element => {
  return (
    <>
      {title}
      {hasNewLabel && (
        <Styled.LabelNewContainer
          labelSize={labelSize}
          labelText={labelText}
          labelType={labelType}
          testId={`new-label-nav-${title}`}
        />
      )}
      {hasNewSubtitleLabel && (
        <Styled.LabelNewSubItem
          labelSize={labelSize}
          labelText={labelText}
          labelType={labelType}
          testId={`new-label-nav-${title}`}
        />
      )}
    </>
  );
};

export const Navigation = ({
  navigationItems,
  LinkComponent,
  rightAlign = false,
  asPath,
}: NavigationProps) => {
  const isAgent = asPath.startsWith('/agent');
  const NavLink = ({ route, children }: { route?: string; children: any }) => {
    if (route) {
      return isAgent ? (
        <span>{children}</span>
      ) : (
        <LinkComponent route={route} passHref legacyBehavior>
          {children}
        </LinkComponent>
      );
    } else {
      return <>{children}</>;
    }
  };

  const handleFakeButtonKeyDown = (
    event: React.KeyboardEvent<HTMLElement>,
  ): void | boolean => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const NavSection = ({
    title,
    href,
    onlyShowOnDesktop,
    subItems,
    route,
    basePath,
    dontShowActiveForSubPaths,
    opensInNewTab,
    hasNewLabel = false,
  }: NavigationItemProps) => {
    return (
      <Styled.StyledLI
        data-testid={`nav-item-${title.split(' ').join('').toLowerCase()}`}
        display={onlyShowOnDesktop ? 'none' : 'block'}
      >
        {subItems ? (
          <CollapsingItem
            openOnHover={true}
            dataTestId="nav-menu"
            labelIsLink={Boolean(route || href)}
            label={
              route || href ? (
                <NavLink route={route}>
                  <TopLevelItemActiveFakeButtonWithRef
                    activeClassName="active"
                    dontShowActiveForSubPaths={dontShowActiveForSubPaths}
                    aria-haspopup="true"
                    data-testid="top-level-nav-item"
                    asPath={asPath}
                    basePath={basePath}
                    href={href ?? route}
                    rightAlign={rightAlign}
                    onKeyDown={handleFakeButtonKeyDown}
                    target={opensInNewTab ? '_blank' : '_self'}
                  >
                    {hasNewLabel ? (
                      <NavTitleWrapper
                        title={title}
                        hasNewLabel={hasNewLabel}
                      />
                    ) : (
                      title
                    )}
                  </TopLevelItemActiveFakeButtonWithRef>
                </NavLink>
              ) : (
                <Styled.TopLevelItemActiveButton
                  dontShowActiveForSubPaths={dontShowActiveForSubPaths}
                  activeClassName="active"
                  data-testid="top-level-nav-item"
                  aria-haspopup="true"
                  asPath={asPath}
                  basePath={basePath}
                  rightAlign={rightAlign}
                >
                  {hasNewLabel ? (
                    <NavTitleWrapper title={title} hasNewLabel={hasNewLabel} />
                  ) : (
                    title
                  )}
                </Styled.TopLevelItemActiveButton>
              )
            }
          >
            <Styled.NavSubLinkList
              rightAlign={rightAlign}
              data-testid="nav-sub-link-list"
            >
              {subItems.map((item: NavigationItemProps, index: number) => (
                <Styled.NavSubLinkListItem
                  key={`sublink-${index}`}
                  hideLinkOnDesktop={item.hideLinkOnDesktop}
                  hideLinkOnMobile={item.hideLinkOnMobile}
                  data-testid="nav-sub-link-list-item"
                >
                  <NavLink route={item.route}>
                    <Styled.StyledActiveLink
                      activeClassName="active"
                      dontShowActiveForSubPaths={item.dontShowActiveForSubPaths}
                      data-testid="active-nav-link"
                      asPath={asPath}
                      basePath={item.basePath}
                      href={item.route ?? item.href}
                      target={item.opensInNewTab ? '_blank' : '_self'}
                      data-tracking={`${
                        item.dataTracking
                          ? item.dataTracking
                          : `nav-${title
                              .split(' ')
                              .join('')
                              .toLocaleLowerCase()}`
                      }`}
                    >
                      {item.opensInNewTab && (
                        <IconWithContentAndLabel
                          dataTestID="IconWithContentAndLabel"
                          IconComponent={<NewTabIcon />}
                          content={<>{item.title}</>}
                          iconHAlignment={'right'}
                          iconVAlignment={'center'}
                          spaceBetween="small"
                          justify="space-between"
                        />
                      )}

                      {item.hasNewSubtitleLabel ? (
                        <NavTitleWrapper
                          title={item.title}
                          hasNewSubtitleLabel={item.hasNewSubtitleLabel}
                          hasNewLabel={false}
                        />
                      ) : (
                        !item.opensInNewTab && item.title
                      )}
                    </Styled.StyledActiveLink>
                  </NavLink>
                </Styled.NavSubLinkListItem>
              ))}
            </Styled.NavSubLinkList>
          </CollapsingItem>
        ) : (
          <NavLink>
            <TopLevelItemActiveLinkWithRef
              activeClassName="active"
              data-testid={'top-level-active-nav-link'}
              dontShowActiveForSubPaths={dontShowActiveForSubPaths}
              asPath={asPath}
              basePath={basePath}
              rightAlign={rightAlign}
              href={route ?? href}
            >
              {hasNewLabel ? (
                <NavTitleWrapper title={title} hasNewLabel={hasNewLabel} />
              ) : (
                title
              )}
            </TopLevelItemActiveLinkWithRef>
          </NavLink>
        )}
      </Styled.StyledLI>
    );
  };

  return (
    <Styled.StyledNavigationList data-testid={`navigation`}>
      {navigationItems &&
        navigationItems.map((item: NavigationItemProps, index: number) => (
          <NavSection
            {...item}
            data-testid={`nav-item-${index}`}
            key={`nav-item-${index}`}
          />
        ))}
    </Styled.StyledNavigationList>
  );
};
