import React, { forwardRef } from 'react';

import {
  AlertsIcon,
  BurgerIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  DrawIcon,
  EmailIcon,
  FacebookIcon,
  FiltersIcon,
  FlagIcon,
  HeartIcon,
  InformationIcon,
  LinkIcon,
  LogoutIcon,
  MapPinIcon,
  NewTabIcon,
  PlusIcon,
  PrintIcon,
  RotateIcon,
  SearchIcon,
  ShareIcon,
  SwitchIcon,
  TrashIcon,
  TwitterIcon,
  VideoIcon,
  VrIcon,
  WhatsappIcon,
} from '@dsch/icons';

import * as S from './NewButton.styled';
import { NewButtonProps } from './NewButton.types';
import { Badge } from '../Badge/Badge';

export enum NewButtonTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  GHOST = 'GHOST',
}

export enum NewButtonSizes {
  X_SMALL = 'X_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum SemanticButtonTypes {
  button = 'button',
  submit = 'submit',
  reset = 'reset',
}

export const Icons: { [key: string]: JSX.Element } = {
  ALERT: <AlertsIcon />,
  BURGER: <BurgerIcon />,
  CHEVRON_LEFT: <ChevronLeftIcon />,
  CHEVRON_RIGHT: <ChevronRightIcon />,
  CHEVRON: <ChevronDownIcon />,
  CLOSE: <CloseIcon />,
  DRAW: <DrawIcon />,
  EMAIL: <EmailIcon />,
  FACEBOOK: <FacebookIcon />,
  FILTERS: <FiltersIcon />,
  FLAG: <FlagIcon />,
  HEART: <HeartIcon />,
  InformationIcon: <InformationIcon />,
  LINK: <LinkIcon />,
  LOGOUT: <LogoutIcon />,
  MAP_PIN: <MapPinIcon />,
  NEW_TAB: <NewTabIcon />,
  PLUS: <PlusIcon />,
  PRINT: <PrintIcon />,
  ROTATE: <RotateIcon />,
  SEARCH: <SearchIcon />,
  SHARE: <ShareIcon />,
  SWITCH: <SwitchIcon />,
  TRASH: <TrashIcon />,
  TWITTER: <TwitterIcon />,
  VIDEO: <VideoIcon />,
  VR: <VrIcon />,
  WHATSAPP: <WhatsappIcon />,
};

const buttonLoadingSpinner = () => <S.ButtonSpinningLoader />;

export const NewButton = forwardRef((props: NewButtonProps, ref) => {
  const {
    badgeNumber,
    buttonSize,
    buttonText = '',
    buttonTextActive,
    buttonType,
    classNames,
    displayIconOnDesktopOnly,
    displayTextOnDesktopOnly,
    fullWidth,
    href,
    iconName,
    id,
    isActive,
    isLoading,
    onBlur,
    onClick,
    onFocus,
    renderLoading,
    showDefaultSubmitLoader = false,
    ...rest
  } = props;

  // eslint-disable-next-line testing-library/render-result-naming-convention
  let loadingComponent = renderLoading;
  if (!loadingComponent && showDefaultSubmitLoader) {
    loadingComponent = () => buttonLoadingSpinner();
  }

  const buttonContent = (
    <>
      <S.ButtonContentContainer
        visibility={isLoading && loadingComponent ? 'hidden' : 'visible'}
      >
        {iconName && (
          <S.StyledIconToggleHolder
            buttonText={buttonText}
            buttonType={buttonType}
            buttonTextActive={buttonTextActive}
            displayTextOnDesktopOnly={displayTextOnDesktopOnly}
            displayIconOnDesktopOnly={displayIconOnDesktopOnly}
            isActive={isActive}
            data-testid={id ? `icon-${id}` : ''}
          >
            {Icons[iconName]}
          </S.StyledIconToggleHolder>
        )}{' '}
        <S.ButtonText
          displayTextOnDesktopOnly={displayTextOnDesktopOnly}
          id={id}
        >
          {buttonTextActive && isActive ? buttonTextActive : buttonText}
        </S.ButtonText>
      </S.ButtonContentContainer>
      {badgeNumber && (
        <S.BadgeContainer>
          <Badge
            ariaLabel={`badge-text-${badgeNumber}`}
            backgroundColor="#4170c4"
            textColor="#ffffff"
            dataTestId={`badge-${badgeNumber}`}
          >
            {badgeNumber}
          </Badge>
        </S.BadgeContainer>
      )}
      {isLoading && loadingComponent && loadingComponent()}
    </>
  );

  return (
    <S.ButtonContainer
      fullWidth={fullWidth}
      className={`button-container${classNames ? ` ${classNames}` : ''}`}
    >
      {href ? (
        <S.StyledButtonHrefLink
          buttonType={buttonType}
          isActive={isActive}
          buttonSize={buttonSize}
          aria-label={buttonText || iconName}
          fullWidth={fullWidth}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          href={href}
          buttonText={buttonText}
          buttonTextActive={buttonTextActive}
          ref={ref}
          id={id}
          {...rest}
        >
          {buttonContent}
        </S.StyledButtonHrefLink>
      ) : (
        <S.StyledButton
          buttonType={buttonType}
          isActive={isActive}
          buttonSize={buttonSize}
          aria-label={buttonText || iconName}
          fullWidth={fullWidth}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          buttonText={buttonText}
          buttonTextActive={buttonTextActive}
          id={id}
          {...rest}
        >
          {buttonContent}
        </S.StyledButton>
      )}
    </S.ButtonContainer>
  );
});
