import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import ReactModalAdapter from '../../../helpers/components/Modal';

import { Burger } from '../Burger/Burger';
import {
  NewButton,
  NewButtonTypes,
  NewButtonSizes,
} from '../NewButton/NewButton';

const StyledBurger = styled(Burger)`
  position: relative;
  color: ${({ theme }) => theme.headerTheme?.burgerColor};
  @media only screen and (min-width: ${({ theme }) =>
      theme.headerTheme?.pageBreakpoint}) {
    display: none;
  }
  border: none;
  outline: 0;
`;

const StyledModal = styled(ReactModalAdapter)`
  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.3);
    @media only screen and (min-width: ${({ theme }) =>
        theme.headerTheme?.pageBreakpoint}) {
      display: none;
    }
  }

  &__content {
    outline: 0;
  }
`;

const CloseButton = styled(NewButton)`
  position: absolute;
  top: 20px;
  right: 14px;
`;

const ContentWrapper = styled.div`
  position: absolute;
  height: 100%;
  border: none;
  width: 264px;
  background: #ffffff;
  overflow: auto;
  right: 0;
  padding-bottom: 72px;
`;

const Content = styled.div`
  margin-top: 50px;
`;

const BurgerModal = (props: { children: any; isOpen?: boolean }) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);

  useEffect(() => {
    setIsOpen(false);
  }, [props]);

  return (
    <>
      <StyledBurger
        onClick={() => setIsOpen(true)}
        data-testid="burger-menu-open-button"
      />
      <StyledModal
        data-testid="nav-modal-container"
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        shouldCloseOnOverlayClick={true}
        contentLabel="Mobile navigation menu"
        ariaHideApp={false}
      >
        <ContentWrapper>
          <CloseButton
            iconName="CLOSE"
            buttonText=""
            buttonType={NewButtonTypes.GHOST}
            buttonSize={NewButtonSizes.SMALL}
            onClick={() => setIsOpen(false)}
            data-testid="burger-menu-close-button"
          />
          <Content>{props.children}</Content>
        </ContentWrapper>
      </StyledModal>
    </>
  );
};

export default BurgerModal;
