import React, { forwardRef } from 'react';

export interface ActiveLinkType {
  asPath?: string;
  activeClassName?: string;
  basePath?: string[] | string;
  children?: any;
  dontShowActiveForSubPaths?: boolean;
  className?: string;
  href?: string;
  showHeaders?: boolean;
  [prop: string]: any;
}

const isActive = (
  asPath: string,
  basePaths?: string[] | string,
  dontShowActiveForSubPaths?: boolean,
): boolean => {
  if (!basePaths) {
    return false;
  }
  const asPathArray = asPath.replace(/\/$/, '').split('/');

  if (asPathArray.length > 3 && dontShowActiveForSubPaths) {
    return false;
  }

  const base = asPathArray[1];

  if (Array.isArray(basePaths)) {
    return basePaths.some((basePath) => basePath === base);
  }

  if (asPath.indexOf(basePaths) > -1) {
    return true;
  }

  return false;
};

export const ActiveLink = forwardRef<HTMLAnchorElement, ActiveLinkType>(
  (props, ref) => {
    const {
      activeClassName,
      asPath,
      basePath = '',
      dontShowActiveForSubPaths,
      children,
      href,
      showHeaders,
      ...rest
    } = props;

    let className = props.className || '';

    if (
      asPath &&
      isActive(asPath, basePath, dontShowActiveForSubPaths) &&
      activeClassName
    ) {
      className = `${className} ${props.activeClassName}`.trim();
    }

    return (
      <>
        {!showHeaders ? (
          <a href={href ?? ''} ref={ref} {...rest} className={className}>
            {children}
          </a>
        ) : (
          <span ref={ref} {...rest} className={className}>
            {children}
          </span>
        )}
      </>
    );
  },
);

export const ActiveButton = forwardRef<HTMLButtonElement, ActiveLinkType>(
  (props, ref) => {
    const {
      activeClassName,
      asPath,
      basePath = '',
      dontShowActiveForSubPaths,
      children,
      ...rest
    } = props;

    let className = props.className || '';
    if (
      asPath &&
      isActive(asPath, basePath, dontShowActiveForSubPaths) &&
      activeClassName
    ) {
      className = `${className} ${props.activeClassName}`.trim();
    }

    return (
      <button ref={ref} {...rest} className={className}>
        {children}
      </button>
    );
  },
);
