type ThemeProps = {
  name: string;
  headerBackgroundColor: string;
  burgerColor: string;
  navLinkColor: string;
  activeNavLinkColor: string;
  fontSize: string;
  desktopFontSize: string;
  pageBreakpoint: string;
};

export const headerThemes: Map<string, ThemeProps> = new Map([
  [
    'COMMERCIAL',
    {
      name: 'COMMERCIAL',
      headerBackgroundColor: '#ffffff',
      burgerColor: '#333333',
      navLinkColor: '#333333',
      activeNavLinkColor: '#333333',
      fontSize: '14px',
      desktopFontSize: '14px',
      pageBreakpoint: '900px',
    },
  ],
  [
    'RESIDENTIAL',
    {
      name: 'RESIDENTIAL',
      headerBackgroundColor: '#ffffff',
      burgerColor: '#333333',
      navLinkColor: '#333333',
      activeNavLinkColor: '#4170c4',
      fontSize: '14px',
      desktopFontSize: '14px',
      pageBreakpoint: '900px',
    },
  ],
  [
    'ALTERNATIVE',
    {
      name: 'ALTERNATIVE',
      headerBackgroundColor: '#transparent',
      burgerColor: '#ffffff',
      navLinkColor: '#ffffff',
      activeNavLinkColor: '#ffffff',
      fontSize: '14px',
      desktopFontSize: '14px',
      pageBreakpoint: '900px',
    },
  ],
  [
    'AGENT_TOOLS',
    {
      name: 'AGENT_TOOLS',
      headerBackgroundColor: '#ffffff',
      burgerColor: '#4170c4',
      navLinkColor: '#333333',
      activeNavLinkColor: '#4170c4',
      fontSize: '14px',
      desktopFontSize: '14px',
      pageBreakpoint: '900px',
    },
  ],
  [
    'MODAL',
    {
      name: 'MODAL',
      headerBackgroundColor: '#ffffff',
      burgerColor: '#333333',
      navLinkColor: '#333333',
      activeNavLinkColor: '#4170c4',
      fontSize: '14px',
      desktopFontSize: '14px',
      pageBreakpoint: '900px',
      inModal: true,
    },
  ],
]);
