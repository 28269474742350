import React from 'react';

import styled from 'styled-components';

import { Label } from '../../../components/Toolkit/Label/Label';

const spaceBetweenSizes = {
  small: '8px',
  medium: '16px',
  large: '24px',
};

type IconWithContentAndLabelPanelProps = {
  labelText?: string;
  labelType?: 'primary' | 'secondary' | 'alert' | 'success' | 'disabled';
  dataTestID: string;
  content: JSX.Element | string;
  IconComponent: JSX.Element;
  iconHAlignment?: 'right' | 'left';
  iconVAlignment?: 'top' | 'center';
  spaceBetween?: 'small' | 'medium' | 'large';
  justify?: 'space-between' | '';
  bgColor?: string;
  dataTrackingId?: string;
};

const Wrapper = styled.div<{
  bgColor?: string;
}>`
  @media only screen and (min-width: 800px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ${({ theme, bgColor }) =>
    bgColor &&
    `
    background-color: ${bgColor};
    padding: ${theme.spacing.S12};
    border-radius: ${theme.border.radius.STANDARD};
    border-bottom: ${theme.spacing.M32};
  `};
`;

const IconWithContentWrapper = styled.div<{
  bgColor?: string;
}>`
  width: 100%;
  :not(:only-child) {
    margin-bottom: ${({ theme }) => theme.spacing.S12};
  }
  @media only screen and (min-width: 800px) {
    :not(:only-child) {
      margin-bottom: 0;
    }
  }
`;

type StyleProps = {
  marginDesktopLeft: string;
  marginDesktopRight: string;
  marginMobile: string;
  iconHAlignment: 'right' | 'left';
  iconVAlignment: 'top' | 'center';
  justify: 'space-between' | '';
  bgColor?: string;
};

const StyledIconWithContent = styled.span<StyleProps>`
  display: flex;
  align-items: ${(props: StyleProps) => props.iconVAlignment};
  ${({ theme, bgColor }) => bgColor && `${theme.fontSize.S14};`}
  justify-content: ${({ justify }: StyleProps) => justify};
  > svg {
    min-width: 20px;
    height: 20px;
    margin: ${({ iconHAlignment, marginMobile }: StyleProps) =>
      iconHAlignment === 'right'
        ? `0 0 0 ${marginMobile}`
        : `0 ${marginMobile} 0 0`};

    order: ${(props: StyleProps) =>
      props.iconHAlignment === 'right' ? '1' : '0'};
    @media only screen and (min-width: 640px) {
      margin: ${({
        iconHAlignment,
        marginDesktopLeft,
        marginDesktopRight,
      }: StyleProps) =>
        iconHAlignment === 'right'
          ? `0 0 0 ${marginDesktopRight}`
          : `0 ${marginDesktopLeft} 0 0`};
    }
  }
`;

const IconWithContentAndLabel = ({
  labelText,
  labelType = 'secondary',
  dataTestID,
  content,
  IconComponent,
  spaceBetween = 'medium',
  iconHAlignment = 'left',
  iconVAlignment = 'top',
  justify = '',
  bgColor,
  dataTrackingId,
}: IconWithContentAndLabelPanelProps) => {
  return (
    <Wrapper data-tracking-id={dataTrackingId} bgColor={bgColor}>
      <IconWithContentWrapper data-testid={`${dataTestID}-icon-content`}>
        <StyledIconWithContent
          justify={justify}
          marginDesktopLeft={spaceBetweenSizes[spaceBetween]}
          marginDesktopRight={spaceBetweenSizes[spaceBetween]}
          marginMobile={spaceBetweenSizes[spaceBetween]}
          iconHAlignment={iconHAlignment}
          iconVAlignment={iconVAlignment}
          bgColor={bgColor}
        >
          {IconComponent}
          {content}
        </StyledIconWithContent>
      </IconWithContentWrapper>
      {labelText && (
        <div data-testid={`${dataTestID}-label`}>
          <Label
            labelText={labelText}
            labelType={labelType}
            labelSize="large"
          />
        </div>
      )}
    </Wrapper>
  );
};

export { IconWithContentAndLabel };
