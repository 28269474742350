import React, { FC, useState } from 'react';

import styled from 'styled-components';

type CollapsingItemProps = {
  label: any;
  children?: any;
  dataTestId: string;
  openOnHover?: boolean;
  labelIsLink?: boolean;
};

type StyleProps = {
  isOpen?: boolean;
  openOnHover?: boolean;
};

const Content = styled.div<StyleProps>`
  overflow: visible;
  display: block;
  color: ${({ theme }) => theme.color.GREY_DARKER};

  @media only screen and (min-width: ${({ theme }) =>
      theme.headerTheme?.pageBreakpoint}) {
    cursor: pointer;
    display: none;
    z-index: 100;
    ${({ isOpen }) => isOpen && `display: block;`};
    position: absolute;
  }
`;

const Wrapper = styled.div<StyleProps>`
  position: relative;
  margin-bottom: 22px;
  :hover {
    ${Content} {
      ${({ openOnHover }) => openOnHover && `display: block;`};
    }
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.headerTheme?.pageBreakpoint}) {
    margin-bottom: 0;
  }
`;

const ToggleOption = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: inherit;
`;

const CollapsingItem: FC<CollapsingItemProps> = ({
  openOnHover,
  children,
  label,
  dataTestId,
  labelIsLink,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLElement>,
  ): void | boolean => {
    if (event.key === 'Enter') {
      labelIsLink && setIsOpen(!isOpen);
    }
  };

  return (
    <Wrapper openOnHover={openOnHover} data-testid={dataTestId}>
      <ToggleOption
        role="button"
        onMouseOut={() => setIsOpen(false)}
        onMouseOver={() => setIsOpen(true)}
        onKeyDown={handleKeyDown}
        onClick={() => setIsOpen(!isOpen)}
        data-testid="collapsable-content-toggle"
      >
        {label}
      </ToggleOption>
      <Content data-testid="collapsable-content" isOpen={isOpen}>
        {children}
      </Content>
    </Wrapper>
  );
};

export default CollapsingItem;
