import React from 'react';

import styled from 'styled-components';

type BadgeProps = {
  ariaLabel: string;
  backgroundColor: string;
  children?: any;
  dataTestId: string;
  textColor: string;
};

type BadgeIndicatorProps = {
  backgroundColor: string;
  textColor: string;
};

const BadgeIndicator = styled.div<BadgeIndicatorProps>`
  color: ${({ textColor }) => textColor};
  font-size: 10px;
  font-weight: 600;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
`;

export const Badge = ({
  ariaLabel,
  backgroundColor,
  children,
  dataTestId,
  textColor,
}: BadgeProps) =>
  children ? (
    <BadgeIndicator
      aria-label={ariaLabel}
      data-testid={dataTestId}
      backgroundColor={backgroundColor}
      textColor={textColor}
    >
      {children}
    </BadgeIndicator>
  ) : null;
