import React, { ReactElement } from 'react';

import styled from 'styled-components';

import { Breadcrumbs, NavLinks } from '../../../types';
import { BreadcrumbList } from '../Breadcrumbs/Breadcrumbs';

const PAGE_BREAK_POINT_640 = '640px';
const PAGE_BREAK_POINT_1038 = '1038px';

export type FooterProps = {
  navLinks?: NavLinks;
  hideFooterOnMobile?: boolean;
  breadcrumbs?: Breadcrumbs;
  BASE_URL: string;
  routes: any;
  content?: ReactElement;
  contact?: ReactElement;
  isMobileView?: boolean;
};

const Wrapper = styled.div<{
  hideFooterOnMobile?: boolean;
  isMobileView?: boolean;
}>`
  background: #ffffff;
  ${({ hideFooterOnMobile }) => (hideFooterOnMobile ? `display: none;` : '')};

  @media only screen and (min-width: 1003px) {
    display: block;
  }
  ${({ isMobileView }) => !isMobileView && `margin-top: auto`}
`;

const BreadcrumbPanel = styled.div`
  max-width: 1040px;
  margin: 0 auto;
  padding: 12px;
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 50%;
    height: 1px;
    width: 100vw;
    transform: translate(-50%, -100%);
    background: ${({ theme }) => theme.color.GREY_LIGHTER};
  }
  @media only screen and (min-width: ${PAGE_BREAK_POINT_1038}) {
    padding: 22px 0;
  }
`;

const FooterWrapper = styled.footer`
  border-top: 1px solid #dddddd;
  background-color: #ffffff;

  @media only screen and (min-width: 1003px) {
    padding-bottom: 0;
  }
`;

const FooterContainer = styled.div<{ isMobileView?: boolean }>`
  max-width: 1040px;
  margin: 0 auto;
  padding: 34px 12px;

  ${({ isMobileView }) =>
    !isMobileView &&
    `
  @media only screen and (min-width: ${PAGE_BREAK_POINT_640}) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media only screen and (min-width: ${PAGE_BREAK_POINT_1038}) {
    padding-left: 0px;
    padding-right: 0px;
  }
  `}
`;

const NavLinkContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  @media only screen and (max-width: ${PAGE_BREAK_POINT_640}) {
    width: 100%;
  }
`;

const NavLinkItem = styled.li<{ isMobileView?: boolean }>`
  min-width: 50%;
  padding-bottom: 10px;
  cursor: pointer;

  ${({ isMobileView }) =>
    !isMobileView &&
    `@media only screen and (min-width: 500px) {
    min-width: 33.3%;
  }

  @media only screen and (min-width: ${PAGE_BREAK_POINT_640}) {
    min-width: 25%;
  }

  @media only screen and (min-width: 800px) {
    flex-grow: 0;
    min-width: auto;
    margin-right: 32px;
    padding-bottom: 0;
    margin-bottom: 14px;
  } 
  `}
`;

const NavLinkAnchor = styled.a`
  &:hover {
    border-bottom: 2px solid #333333;
  }
  color: #333333;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
`;

const InfoContainer = styled.div`
  display: flex;
  color: #717171;
  padding-top: 22px;
  font-size: 10px;

  @media only screen and (min-width: ${PAGE_BREAK_POINT_640}) {
    padding-top: 16px;
  }
`;

const NavigationSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const makeLinks = (FooterNavLinkConfig: NavLinks, isMobileView?: boolean) =>
  FooterNavLinkConfig.map(
    ({ name, url, openInNew, className, onClick }, index) => (
      <NavLinkItem key={index} isMobileView={isMobileView}>
        <NavLinkAnchor
          data-testid="footer_link"
          href={url}
          target={openInNew ? '_blank' : undefined}
          className={className}
          onClick={onClick}
        >
          {name}
        </NavLinkAnchor>
      </NavLinkItem>
    ),
  );

export const Footer = ({
  navLinks,
  hideFooterOnMobile,
  breadcrumbs,
  BASE_URL,
  routes,
  content,
  contact,
  isMobileView,
}: FooterProps) => (
  <Wrapper hideFooterOnMobile={hideFooterOnMobile}>
    {breadcrumbs && (
      <BreadcrumbPanel data-testid="footer-breadcrumbs">
        <BreadcrumbList
          routes={routes}
          BASE_URL={BASE_URL}
          breadcrumbs={breadcrumbs}
        />
      </BreadcrumbPanel>
    )}
    <FooterWrapper data-testid="footer">
      <FooterContainer isMobileView={isMobileView}>
        <NavigationSection>
          {navLinks && (
            <NavLinkContainer>
              {makeLinks(navLinks, isMobileView)}
            </NavLinkContainer>
          )}
          {contact}
        </NavigationSection>
        {content && <InfoContainer>{content}</InfoContainer>}
      </FooterContainer>
    </FooterWrapper>
  </Wrapper>
);
